import { Pipe, PipeTransform } from '@angular/core';
import { Action, Module, PERMISSION, Subadmin_permission } from 'src/app/constant/app-constant';
import { DataTransferService } from 'src/app/modules/shared/services/data-transfer.service';

@Pipe({
  name: 'access'
})
export class AccessPipe implements PipeTransform {

  profileDetails: any;
  access: any = null;
  safeRoute;
  constructor(
    private $dataTransferService: DataTransferService
  ) {
    this.profileDetails = $dataTransferService.profileInfo;

    if (this.profileDetails && this.profileDetails['data']
      && this.profileDetails['data']['jMart']
      && this.profileDetails['data']['jMart']['adminDetails']
      && this.profileDetails['data']['jMart']['adminDetails']['roles']
    ) {
      this.access = this.profileDetails['data']['jMart']['adminDetails']['roles']
      //  console.log('inside acesss',this.access);
    }
  }
  transform(module: string, action?): any {
    if (this.profileDetails ) { //&& this.profileDetails['data']['is_admin']
      return true;
    }

    const value = this.access;
    if (module === Module.DASHBOARD || module === Module.SUBADMIN) {
      return false;
    }

    if (action) {
      return value && value[module] == action;
    }

    return (value && value[module]
      && value[module] != Action.NONE)
  }

  /*---------set Route in case of sub-admin------*/

  setSafeRoutes(roles = this.access) {
    if (this.safeRoute) {
      return;
    }
    for (let item of Subadmin_permission) {
      if (roles[item['Manage_Type']] > 1) {
        this.safeRoute = item['path']
        return;
      }
    }
  }

}
