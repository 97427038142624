export const INVENTORY = 'inventory';
export const ADMIN = 'admin';
export const DASHBOARD = 'dashboard';
export const LOGIN = 'login';
export const FORGOT_PASSWORD = 'forgot-password';
export const RESET_PASSWORD = 'reset-password';
export const LINK_EXPIRED = 'link-expired';
export const ACCOUNT = 'account';
export const DAYEND = 'day-end';
export const WALLET_LEDGER = 'wallet-ledger';
