import * as routes from "./routes";

export const DASHBOARD = `/${routes.DASHBOARD}`
export const INVENTORY = `/${routes.INVENTORY}`
export const DAYEND = `${routes.DAYEND}`
export const WALLET_LEDGER = `${routes.WALLET_LEDGER}`;

export const LOGIN = `/${routes.ACCOUNT}/${routes.LOGIN}`;
export const FORGOT_PASSWORD = `/${routes.ACCOUNT}/${routes.FORGOT_PASSWORD}`;
export const RESET_PASSWORD = `/${routes.ACCOUNT}/${routes.RESET_PASSWORD}`;
