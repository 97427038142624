import { environment } from 'src/environments/environment';
// store list
export const JMART = 'j-mart';
export const STORE = 'store';
export const STORE_LIST = 'j-mart/stores';
export const STORE_PRODUCTS = "j-mart/store/products";

export const ADMIN = 'j-mart/profile';
//Product List
// export const PRODUCT_LIST = (id:any) => `store/product/list/${id}`;
export const PRODUCT_LIST = `j-mart/store/product/list`;
export const LOGIN = 'j-mart/login';
export const FORGOT_PASSWORD = 'j-mart/forgot-password';
export const CHANGE_PASSWORD = 'j-mart/change-password';
export const LOGOUT = 'j-mart/logout';
export const RESET_PASSWORD = 'common/verify-token';
export const VALIDATE_TOKEN = 'common/verifyToken';
//header
export const USER = 'user';
export const FEEDBACK = 'feedback';
export const NOTIFICATION = 'notification';
export const DASHBOARD = 'dashboard';
export const BASE_EMPLOYEE = '/admin/employees';
export const DETAIL = 'details';
export const ADD_EMPLOYEE = 'j-mart/employee';
export const NOTIFICATION_LIST = `j-mart/admin-notification`;
export const DELETE_NOTIFICATION = (id: any) =>
  `j-mart/admin-notification/${id}`;
export const NOTIFICATION_COUNT = `j-mart/notification-count`;

export const ELASTIC_INVENTORY_LIST = `elastic/inventory/list`;
export const ELASTIC_WALLET_LEDGER = `elastic/wallet_ledger/list`;
