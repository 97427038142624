import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { INVENTORY, DASHBOARD ,DAYEND, WALLET_LEDGER } from '../../constant/routes'
import { Module } from '../../constant/app-constant';
import { AccessGuard } from 'src/app/guards/access.guard';
const routes: Routes = [
  {
    path: '', component: LayoutComponent, children: [
      // {
      //   path: '',
      //   redirectTo: INVENTORY,
      //   pathMatch : 'full'
      // },
      {
          path: DASHBOARD,
          loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
          // canActivate: [AccessGuard],
          data: {
              module: Module.DASHBOARD,
          },
      },
      {
        path: INVENTORY,
        loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
        canActivate: [AccessGuard],
        data: { 
          module: Module.INVENTORY,
        },
      },
      {
        path: DAYEND,
        loadChildren: () => import('./day-end/day-end.module').then(m => m.DayEndModule),
        canActivate: [AccessGuard],
        data: { 
          module: Module.INVENTORY,
        },
      },
      {
        path: WALLET_LEDGER,
        loadChildren: () => import('./wallet-ledger/wallet-ledger.module').then(m => m.WalletLedgerModule),
        // canActivate: [AccessGuard],
        // data: { 
        //   module: Module.INVENTORY,
        // },
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
